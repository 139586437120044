// DEFINE API DOMAIN
const API_DOMAIN =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_DOMAIN
    : process.env.REACT_APP_DEV_API_DOMAIN;

// DEFINE PRIVATE API KEY
const GET_API_KEY = () =>
  fetch("http://localhost:5000", {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export { API_DOMAIN, GET_API_KEY };
