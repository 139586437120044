export default class Product {
  /**
   * Get products of company
   * @param id {int|null}
   * @returns {Promise<Response>}
   * @constructor
   */
  static async Read(id = null) {
    // let url = "http://localhost:5000/products";
    let url = `${process.env.REACT_APP_URL}proxy.php/products`;
    if (id !== null) url = `${url}/${id}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
}
