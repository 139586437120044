export default class ProductGroup {
  /**
   * Get product groups of company
   */
  static async Read(id = null) {
    // let url = "http://localhost:5000/groups";
    let url = `${process.env.REACT_APP_URL}proxy.php/groups`;
    if (id !== null) url = `${url}/${id}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
}
