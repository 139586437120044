/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Menu from "@mui/material/Menu";
import MDBox from "components/MDBox";

// react
import { useState } from "react";
// import { useParams } from "react-router-dom";

// components
import NotificationItem from "examples/Items/NotificationItem";

// Models
// import Price from "service/Models/Price";

export default function data(_product, updateInfoPriceFormCallback = null, noAction = false) {
  // a copy of the products for safe modification
  const product = { ..._product };
  const [selectedPrice, setSelectedPrice] = useState(null);
  const priceRows = [];
  const renewalPriceRows = [];

  // utilities
  // const getRentPriceLength = () => {
  //   const result = [];
  //   product.prices.forEach((price) => {
  //     if (!price.is_renewal_price) result[result.length] = price;
  //   });
  //   return result.length;
  // };

  const numWord = (value) => {
    const prepVal = Math.abs(value) % 100;
    const num = value % 10;
    if (value === 7) return "дней (неделя)";
    if (value === 14) return "дней (2 недели)";
    if (value === 21) return "день (3 недели)";
    if (value === 28) return "дней (месяц)";
    if (prepVal > 10 && prepVal < 20) return "дней";
    if (num > 1 && num < 5) return "дня";
    if (num === 1) return "день";
    return "дней";
  };

  const handleOpenMenu = (price) => setSelectedPrice(price);
  const handleCloseMenu = () => setSelectedPrice(null);

  // const handleDeleteInterval = () => {
  //   handleCloseMenu();
  //
  //   let message = "Вы действительно хотите удалить данную цену?";
  //   if (!selectedPrice.is_renewal_price && getRentPriceLength() === 1 && product.active) {
  //     message += " Удаление цены приведет к автоматической смене статуса товара на 'Неактивный'";
  //   }
  //   const agreeText = "Удалить";
  //   const agreeColor = "error";
  //   modalWindowCallback({
  //     message,
  //     agreeText,
  //     agreeColor,
  //     async action() {
  //       const response = await Price.Delete(product.id, selectedPrice.id);
  //       if (response) {
  //         _product.prices.forEach((price, priceIndex) => {
  //           if (selectedPrice.id === price.id) product.prices.splice(priceIndex, 1);
  //         });
  //         if (!getRentPriceLength()) product.active = false;
  //         updateProductCallback({ ...product });
  //       } else {
  //         console.log(response);
  //       }
  //     },
  //   });
  // };

  const handleEditPrice = () => {
    handleCloseMenu();
    updateInfoPriceFormCallback({
      renewal: false,
      price: selectedPrice,
    });
  };

  // const handleMove = () => {
  //   handleCloseMenu();
  //   let message = "Вы действительно хотите изменить статус данной цены?";
  //   if (!selectedPrice.is_renewal_price && getRentPriceLength() === 1 && product.active) {
  //     message += " Перемещение приведет к автоматической смене статуса товара на 'Неактивный'";
  //   }
  //   const agreeText = "Переместить";
  //   const agreeColor = "info";
  //   modalWindowCallback({
  //     message,
  //     agreeText,
  //     agreeColor,
  //     async action() {
  //       const response = await Price.InvertStatus(_product.id, selectedPrice.id);
  //       if (response.success === true) {
  //         const newPriceType = !selectedPrice.is_renewal_price;
  //         _product.prices.forEach((price, priceIndex) => {
  //           if (selectedPrice.id === price.id)
  //             product.prices[priceIndex].is_renewal_price = newPriceType;
  //         });
  //         if (getRentPriceLength() === 0) {
  //           product.active = false;
  //         }
  //         updateProductCallback({ ...product });
  //       } else {
  //         console.log(response);
  //       }
  //     },
  //   });
  // };

  // const handleCopy = () => {
  //   handleCloseMenu();
  //   let message = "Вы действительно хотите скопировать эту цену в ";
  //   if (selectedPrice.is_renewal_price) message += "'Цены аренды'";
  //   else message += "'Цены продления'";
  //   const agreeText = "Скопировать";
  //   const agreeColor = "info";
  //   modalWindowCallback({
  //     message,
  //     agreeText,
  //     agreeColor,
  //     async action() {
  //       const newPriceData = {
  //         days: selectedPrice.days,
  //         price: selectedPrice.price,
  //         is_renewal_price: !selectedPrice.is_renewal_price,
  //       };
  //       const response = await Price.Create(newPriceData, _product.id);
  //       if (response.success === true) {
  //         product.prices[product.prices.length] = response.price;
  //         updateProductCallback({ ...product });
  //       } else {
  //         console.log(response);
  //       }
  //     },
  //   });
  // };

  // render context menu
  const renderMenu = () => (
    <Menu
      anchorEl={selectedPrice === null ? null : selectedPrice.anchor}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(selectedPrice)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* {selectedPrice.price_type !== 2 && ( */}
      {/*  <NotificationItem */}
      {/*    onClick={handleDeleteInterval} */}
      {/*    icon={<Icon>delete</Icon>} */}
      {/*    title="Удалить цену" */}
      {/*  /> */}
      {/* )} */}
      <NotificationItem onClick={handleEditPrice} icon={<Icon>edit</Icon>} title="Редактировать" />
      {/* {selectedPrice !== null && ( */}
      {/*  <NotificationItem */}
      {/*    icon={<Icon>compare_arrows</Icon>} */}
      {/*    onClick={handleMove} */}
      {/*    title={ */}
      {/*      selectedPrice.is_renewal_price */}
      {/*        ? "Переместить в 'Цены аренды'" */}
      {/*        : "Переместить в 'Цены продления'" */}
      {/*    } */}
      {/*  /> */}
      {/* )} */}
      {/* {selectedPrice !== null && ( */}
      {/*  <NotificationItem */}
      {/*    icon={<Icon>content_copy</Icon>} */}
      {/*    onClick={handleCopy} */}
      {/*    title={ */}
      {/*      selectedPrice.is_renewal_price */}
      {/*        ? "Скопировать в 'Цены аренды'" */}
      {/*        : "Скопировать в 'Цены продления'" */}
      {/*    } */}
      {/*  /> */}
      {/* )} */}
    </Menu>
  );

  if (product.prices !== undefined && product.prices.length !== 0) {
    product.prices.forEach((priceItem) => {
      const rowItem = {
        days: (
          <MDTypography variant="button" color="text" fontWeight="regular">
            {`${priceItem.days} ${numWord(priceItem.days)}`}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="button" color="text" fontWeight="regular">
            {`${priceItem.price} руб.`}
          </MDTypography>
        ),
      };
      if (!noAction)
        rowItem.action = (
          <MDBox>
            <MDTypography
              onClick={(e) => handleOpenMenu({ ...priceItem, anchor: e.target })}
              component="div"
              color="text"
              sx={{ cursor: "pointer" }}
            >
              <Icon>more_vert</Icon>
            </MDTypography>
            {selectedPrice !== null && renderMenu()}
          </MDBox>
        );

      if (priceItem.price_type === 0) {
        priceRows[priceRows.length] = rowItem;
      } else if (priceItem.price_type === 1) {
        renewalPriceRows[renewalPriceRows.length] = rowItem;
      }
    });
  }

  if (noAction === true) {
    return {
      columns: [
        { Header: "Кол-во дней", accessor: "days", width: "35%", align: "left" },
        { Header: "Цена", accessor: "price", align: "right" },
      ],
      priceRows,
      renewalPriceRows,
    };
  }
  return {
    columns: [
      { Header: "Срок аренды", accessor: "days", width: "35%", align: "left" },
      { Header: "Цена", accessor: "price", align: "left" },
      { Header: "Действия", accessor: "action", align: "right" },
    ],
    priceRows,
    renewalPriceRows,
  };
}
