/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// mui components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Components
import Gallery from "examples/NewGallery";

// PropTypes
import PropTypes from "prop-types";

// React
import pricesData from "layouts/products/data/pricesData";
import DataTable from "examples/Tables/DataTable";

function ProductInfo({ productInfo, companyContacts }) {
  document.title = productInfo.name;

  const { columns, priceRows, renewalPriceRows } = pricesData(productInfo, null, null, null, true);

  return (
    <Grid container columnSpacing={6} py={2} px={1}>
      <Grid item xs={12} md={6}>
        <Gallery images={productInfo.images} mainImage={productInfo.main_image} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MDTypography variant="h3">{productInfo.name}</MDTypography>
        <MDBox sx={{ marginTop: { xs: "0.5rem", md: "1rem" } }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Описание
          </MDTypography>
          <MDTypography
            variant="body2"
            color="secondary"
            fontWeight="regular"
            pl={1}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {productInfo.site_description || "Описание отстутствует"}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ marginTop: { xs: "0.5rem", md: "1rem" } }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Стоимость аренды
          </MDTypography>
          <DataTable
            table={{ columns, rows: priceRows }}
            entriesPerPage={false}
            isSorted={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
        {renewalPriceRows.length !== 0 && (
          <MDBox sx={{ marginTop: { xs: "0.5rem", md: "1rem" } }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Стоимость продления
            </MDTypography>
            <DataTable
              table={{ columns, rows: renewalPriceRows }}
              entriesPerPage={false}
              isSorted={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        )}
        <MDBox sx={{ marginTop: { xs: "0.5rem", md: "1rem" } }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Контакты для связи
          </MDTypography>
          <MDTypography variant="body2" color="secondary" fontWeight="regular" pl={1} pt={1}>
            Телефон: {companyContacts.phone}
          </MDTypography>
          <MDTypography variant="body2" color="secondary" fontWeight="regular" pl={1} pt={1}>
            Email: {companyContacts.email}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Typechecking for ProductInfo
ProductInfo.propTypes = {
  productInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    site_description: PropTypes.string.isRequired,
    images: PropTypes.oneOfType([PropTypes.array]).isRequired,
    main_image: PropTypes.oneOfType([PropTypes.object]).isRequired,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        is_renewal_price: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
      })
    ).isRequired,
  }).isRequired,
  companyContacts: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProductInfo;
