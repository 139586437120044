/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// mui components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material dashboard 2 Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React example components
import BasicLayout from "examples/LayoutContainers/PageLayout";
import GreetingsNavbar from "examples/Navbars/GreetingsNavbar";
import ImageCard from "examples/Cards/ImageCard";
import Preloader from "layouts/preloader";
import Filter from "examples/Filter";

// Models
import Product from "service/Models/Product";

// React
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Models
import ProductGroup from "service/Models/ProductGroup";

// Yandex metrika
// import { YMInitializer } from "react-yandex-metrika";

// routes
import routes from "routes";

function Greetings() {
  // set route name
  const location = useLocation();
  const route = routes.find((pRoute) => pRoute.route === location.pathname);
  if (route) document.title = route.name;

  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchField, setSearchField] = useState("");
  // get 0-18 age filter options
  const getAgeFilterOptions = () => {
    const res = [];
    for (let i = 0; i < 19; i += 1) {
      res[i] = {
        label: i.toString(10),
        id: i,
      };
    }
    return res;
  };
  const freshFilters = [
    {
      name: "groupFilter",
      label: "По группам",
      value: {
        label: "Все",
        id: 0,
      },
      options: [
        {
          label: "Все",
          id: 0,
        },
      ],
      select: true,
    },
    {
      name: "ageFilter",
      label: "По возрасту",
      value: {
        label: "0",
        id: 0,
      },
      options: getAgeFilterOptions(),
      select: true,
    },
  ];
  const [filters, setFilters] = useState(freshFilters);
  const updateFiltersCallback = (value) => setFilters(value);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  // filter out
  useEffect(() => {
    // reset the visibility of all products
    products.forEach((product, index) => {
      products[index].view = false;
    });

    // search field filter out
    if (searchField !== "") {
      products.forEach((product, index) => {
        if (product.name.indexOf(searchField) !== -1) products[index].view = true;
      });
    } else {
      products.forEach((product, index) => {
        products[index].view = true;
      });
    }

    // group filter out
    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[0].value.id === 0) products[index].view = true;
        else if (product.product_group_id === filters[0].value.id) products[index].view = true;
        else products[index].view = false;
      }
    });

    // age filter out
    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[1].value.id === 0) products[index].view = true;
        else if (
          product.age_range_bottom <= filters[1].value.id &&
          product.age_range_top >= filters[1].value.id
        )
          products[index].view = true;
        else products[index].view = false;
      }
    });

    // set update products
    setProducts([...products]);
  }, [filters]);

  const handleInputChange = (event) => {
    setSearchField(event.target.value);
    setFilters([...filters]);
  };

  const handleResetFilters = () => {
    setSearchField("");
    const nFilters = freshFilters;
    groups.forEach((group) => {
      nFilters[0].options[nFilters[0].options.length] = {
        label: group.name,
        id: group.id,
      };
    });

    setFilters(freshFilters);
  };

  useEffect(() => {
    const getProducts = () =>
      Product.Read()
        .then((response) => {
          if (!response.ok) return Promise.reject(response);
          return response.json();
        })
        .then((json) => {
          const activeProducts = json.filter((product) => product.active);
          const activeCompanyProducts = activeProducts.filter((product) => product.company_active);
          setProducts(activeCompanyProducts);
        })
        .catch((err) => {
          console.error(err);
          if (err.status === 403) navigate("/not-found");
          else if (err.status >= 500) navigate("/server-error");
        });

    const getGroups = () =>
      ProductGroup.Read()
        .then((response) => {
          if (!response.ok) return Promise.reject(response);
          return response.json();
        })
        .then((json) => {
          const activeGroups = json.filter(
            (group) => group.company_active && group.active_products
          );
          activeGroups.forEach((group) => {
            filters[0].options[filters[0].options.length] = {
              label: group.name,
              id: group.id,
            };
          });
          setGroups(activeGroups);
        })
        .catch((err) => {
          console.error(err);
          if (err.status === 403) navigate("/not-found");
          else if (err.status >= 500) navigate("/server-error");
        });

    Promise.all([getGroups(), getProducts()]).then(() => {
      setFilters([...filters]);
      setIsLoaded(true);
    });
  }, []);

  const [mobileFilters, setMobileFilters] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const interractMobileFilters = () => setMobileFilters(!mobileFilters);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.md) {
        setMobileView(true);
        setMobileFilters(false);
      } else {
        setMobileView(false);
        setMobileFilters(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <BasicLayout>
      {/* <YMInitializer */}
      {/*  accounts={[93423186]} */}
      {/*  options={{ clickMap: true, trackLinks: true, accurateTrackBounce: true }} */}
      {/* /> */}
      <GreetingsNavbar signIn={false} />
      <MDBox pt={14} pb={3} px={{ xs: 1, sm: 3 }} mx="auto" textAlign="center">
        <MDBox maxWidth="md" mx="auto">
          <MDTypography variant="h3" fontWeight="medium">
            Почему это выгодно?
          </MDTypography>
          <MDTypography variant="body1" fontWeight="regular" mt={1} px={{ xs: 1, sm: 0 }}>
            Многие вещи нужны на короткий промежуток времени. А игрушки, порой, быстро надоедают
            нашим маленьким непоседам. Приятные цены - любая семья может позволить своему ребенку
            играть в самые крутые дорогостоящие игрушки. Взяли на пару недель - ребенок наигрался -
            и легко поменяли на другую!
          </MDTypography>
        </MDBox>
        <MDBox maxWidth="xl" mx="auto" pt={2} pb={1} px={{ xs: 0, sm: 2, lg: 3 }}>
          <Card>
            <MDBox
              py={1}
              px={{ xs: 0, sm: 2, lg: 3 }}
              mt={1}
              mx={2}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox textAlign="left">
                <MDTypography variant="body2" color="text" textAlign="left">
                  Поиск товаров
                </MDTypography>
              </MDBox>
              <MDBox
                display={{ xs: "inline-block", md: "none" }}
                lineHeight={0}
                py={1.5}
                pl={1.5}
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={interractMobileFilters}
              >
                <Icon
                  fontSize="default"
                  title={mobileFilters ? "Скрыть фильтры" : "Показать фильтры"}
                >
                  {mobileFilters ? "close" : "filter_list"}
                </Icon>
              </MDBox>
            </MDBox>
            <Collapse in={!mobileView || mobileFilters} timeout="auto">
              <MDBox py={1} px={{ xs: 0, sm: 2, lg: 3 }} mb={1} mx={2} display="flex">
                <Grid container alignItems="flex-end" spacing={3}>
                  <Grid item xs={12} sm={5} order={{ xs: 2, sm: 1 }}>
                    <MDInput
                      name="searchField"
                      label="Поиск по названию"
                      type="text"
                      size="small"
                      sx={{ width: "100%" }}
                      value={searchField}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} order={{ xs: 3, sm: 2 }}>
                    <Filter
                      filters={filters}
                      widthParams={{ xs: 12, sm: 6 }}
                      onChangeFilters={updateFiltersCallback}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    textAlign={{ xs: "right", sm: "center" }}
                    alignSelf="center"
                    order={{ xs: 1, sm: 3 }}
                  >
                    <MDBox>
                      <MDButton
                        iconOnly
                        size="small"
                        title="Сбросить фильтры"
                        variant="outlined"
                        color="error"
                        onClick={handleResetFilters}
                      >
                        <Icon>refresh</Icon>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Collapse>
          </Card>
        </MDBox>
        <MDBox maxWidth="xl" mx="auto" pt={1} pb={6} px={{ xs: 0, sm: 2, lg: 3 }}>
          <Card>
            {!isLoaded ? (
              <Preloader fullScreen={false} />
            ) : (
              <Grid container spacing={3} py={3} px={2}>
                {products
                  .filter((product) => product.view === true)
                  .map((product) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={product.name}>
                      <ImageCard
                        fixedHeight="200px"
                        image={product.main_image !== undefined ? product.main_image.path : ""}
                        fullWidth={product.main_image === undefined}
                        title={product.name}
                        description={product.site_description}
                        action={{
                          type: "internal",
                          route: `/products/${product.id}`,
                          color: "info",
                          label: "Просмотреть",
                        }}
                      />
                    </Grid>
                  ))}
                {products.filter((product) => product.view === true).length === 0 && (
                  <MDBox mx="auto">
                    <MDTypography m={5} variant="subtitle1" color="text">
                      Товаров не обнаружено
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
            )}
          </Card>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default Greetings;
