export default class Company {
  /**
   * Get product group by id
   */
  static async GetPublicContacts(pId) {
    const url = `${process.env.REACT_APP_API_DOMAIN}api/company/public/${pId}`;
    return fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
}
